import { request } from '@/util/request'

export function getInspectList(params) {
  return request({
    url: '/api/Lis/GetInspectList',
    method: 'get',
    params,
  })
}

export function getInspecInfo(params) {
  return request({
    url: '/api/Lis/GetInspectInfo',
    method: 'get',
    params,
  })
}

export function getInspectWSWInfo(params) {
  return request({
    url: '/api/Lis/GetInspectWSWInfo',
    method: 'get',
    params,
  })
}
